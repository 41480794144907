import { renderProductStatus, renderProductStatusTitle } from "@vendor-app/utils/displayUtils";

import { IPurchasable } from "@smartrr/shared/entities/Purchasable";
import { IPurchasableVariant } from "@smartrr/shared/entities/PurchasableVariant";
import { ProductApi } from "@smartrr/shared/interfaces/product/api";
import { VariantApi } from "@smartrr/shared/interfaces/variant/api";
import { Badge } from "@shopify/polaris";
import styled from "styled-components";

const BadgeWrapper = styled.div`
  & span {
    white-space: nowrap;
  }
`;

export const StatusBadge = ({
  obj,
}: {
  obj: IPurchasable | IPurchasableVariant | ProductApi.Product | VariantApi.Variant;
}) => {
  const status = renderProductStatus(obj);
  const title = renderProductStatusTitle(obj);
  return (
    <BadgeWrapper>
      <Badge status={status}>{title}</Badge>
    </BadgeWrapper>
  );
};
